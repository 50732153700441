import React, { useEffect, useState } from "react";
import API from "../../api/API";
import { useDispatch, useSelector } from "react-redux";
import { setSavedSpecialPatients } from "../../redux/JiraniSlice";
import { toast } from "react-toastify";
import Icon from "@mdi/react";
import { mdiCheckCircle } from "@mdi/js";
import Loader from "../Loader";
import { Oval } from "react-loader-spinner";

function SpecialVerificationTab() {
  const token = useSelector((state) => state.authorization.token);
  const savedSpecialPatients = useSelector(
    (state) => state.jirani.savedSpecialPatients
  );
  const [special, setSpecial] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    if (savedSpecialPatients?.length > 0) {
      setSpecial(savedSpecialPatients);
      setIsLoading(false);
    }

    const specialPatintsFetch = async () => {
      const response = await API.specialPatientFetch(token);
      if (response?.status === 200) {
        setSpecial(response?.data);
        dispatch(setSavedSpecialPatients(response.data));
        setIsLoading(false);
      }
      setIsLoading(false);
    };
    specialPatintsFetch();
  }, []);

  //return picture based on gender
  const getPatientPicture = (gender) => {
    if (gender === "MALE") {
      return require("../../media/img/male.jpg");
    } else {
      return require("../../media/img/female.jpg");
    }
  };

  return isLoading ? (
    <div className="d-flex justify-content-center align-items-center">
      <Oval size={2} wrapperClass={"d-flex"} />
    </div>
  ) : (
    <div className="col-lg-8 py-2">
      <div className="card card-rounded h-100">
        <div className="card-body">
          <div className="d-sm-flex justify-content-between align-items-start py-3">
            <div>
              <h4 className="card-title card-title-dash">Special Requests</h4>
              <p className="card-subtitle card-subtitle-dash">
                5 new special requests
              </p>
            </div>
          </div>
          <div className="row">
            {special?.slice(0, 5).map((patient) => (
              <>
                <div className="col-12 border-top py-3">
                  <div className="d-flex justify-content-between">
                    <div className="me-2">
                      <div className="d-flex align-items-center">
                        <div className="me-2">
                          <img
                            src={
                              patient?.patient?.patient_images
                                ? patient?.patient?.patient_images
                                : getPatientPicture(
                                    patient?.patient?.patient_gender
                                  )
                            }
                            alt=""
                            className="w-100 rounded"
                            style={{
                              height: "4rem",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div>
                          <h6>Jirani {patient.patient.id}</h6>
                          <div className="d-flex">
                            <div className="me-2">
                              {patient?.patient?.patient_gender}
                            </div>
                            <div>
                              {patient?.patient?.minimum_age} -{" "}
                              {patient?.patient?.maximum_age}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ maxWidth: "50%" }}>
                      <p>{patient.reason}</p>
                    </div>
                    <div>
                      <button className="btn btn-secondary text-primary">
                        <i className="me-2">
                          {" "}
                          <Icon path={mdiCheckCircle} size={1} />{" "}
                        </i>
                        Verify
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecialVerificationTab;
