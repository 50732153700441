import React, { useState } from "react";
import axiosInstance from "../auth/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import CryptoJS from "crypto-js";
import env from "react-dotenv";
import { useDispatch } from "react-redux";
import { setToken } from "../redux/AuthSlice";
import { useNavigate } from "react-router-dom";

const secretKey = env.ENCRYPTION_KEY;

function Login() {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Obtaining the login data from the form
  const handleChanges = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    name === "username" && setUsername(value);
    name === "password" && setPassword(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.info("Please wait...");
    const loginFetch = async () => {
      await axiosInstance
        .post("/auth/login/", {
          username,
          password,
        })
        .then((response) => {
          const token = response.data.data.token;
          const userId = response.data.data.user.id;
          //encrypt token
          const cipherText = CryptoJS.AES.encrypt(token, secretKey).toString();

          //save the key locally and state management
          localStorage.setItem("jirani", cipherText);
          localStorage.setItem("jiraniId", userId);
          dispatch(setToken(token));
          navigate("/");
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            toast.dismiss();
            toast.error("wrong credetials", {
              autoClose: true,
            });
          }
        });
    };

    if (username && password) {
      loginFetch();
    } else {
      toast.error("please provide both username and password", {
        autoClose: true,
      });
    }
  };

  const goToWelcomePage = () => {
    navigate("/welcome");
  };

  return (
    <div className="vh-100 w-100 p-3">
      <div className="container-fluid bg-light h-100 w-100">
        <div className="row justify-content-center align-items-center h-100 w-100">
          <div className="col-md-5 text-center bg-secondary p-4 shadow border">
            <div className="my-2" onClick={goToWelcomePage}>
              <h1 className="display-3 text-primary">Jirani</h1>
            </div>
            <div className="title-text">
              <h3>Login to your account</h3>
            </div>
            <form className="p-3" onSubmit={handleSubmit}>
              <div className="form-floating">
                <input
                  className="form-control my-4"
                  type="text"
                  placeholder="Username"
                  name="username"
                  onChange={handleChanges}
                />
                <label for="username">Username</label>
              </div>
              <div className="form-floating">
                <input
                  className="form-control"
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleChanges}
                />
                <label for="username">Password</label>
              </div>
              <div className="my-4">
                <button className="btn bg-primary text-white p-3">Login</button>
              </div>
            </form>
            <div className="my-2">
              {" "}
              <a href="form.html">Forget your password?</a>
              <p>
                Don't Have an account?{" "}
                <a href="sign-up.html" className="text-primary">
                  Signup
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
}

export default Login;
