import { mdiHospital } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import API from "../../api/API";
import { setSavedJirani } from "../../redux/JiraniSlice";
import { toast } from "react-toastify";
import DateConverter from "../../utils/DateConverter";
import Loader from "../Loader";

function PatientsTab() {
  const token = useSelector((state) => state.authorization.token);
  const savedJirani = useSelector((state) => state.jirani.savedJirani);
  const savedSelectedInstitute = useSelector(
    (state) => state.authorization.savedSelectedInstitute
  );
  const [isLoading, setIsLoading] = useState(true);
  const [jiraniData, setJiraniData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    //loading the cashed Data if available before fetching
    if (savedJirani?.length > 0) {
      if (savedSelectedInstitute === "all") {
        setJiraniData(savedJirani);
      } else {
        let filteredData = savedJirani.filter(
          (item) =>
            item?.patient_institution?.institution_type ===
            savedSelectedInstitute
        );
        setJiraniData(filteredData);
      }
      setIsLoading(false);
    }

    const jiraniFetch = async () => {
      const response = await API.patientsFetch(token);
      if (!response) {
        toast.error("Network problems", { autoClose: true });
      }
      if (response.status === 200) {
        const data = response.data;
        if (savedSelectedInstitute === "all") {
          setJiraniData(data);
        } else {
          let filteredData = data.filter(
            (item) =>
              item?.patient_institution?.institution_type ===
              savedSelectedInstitute
          );
          setJiraniData(filteredData);
        }
        dispatch(setSavedJirani(response.data));
        setIsLoading(false);
      }
    };
    jiraniFetch();
  }, [savedSelectedInstitute]);

  //processing images
  const getPatientImage = (itemId) => {
    if (jiraniData.length > 0) {
      const item = jiraniData.filter((x) => x.id === parseInt(itemId))[0];
      const patientImage = item.patient_images;
      const patientSex = item.patient_gender;
      if (!patientImage) {
        if (patientSex === "MALE") {
          return require("../../media/img/male.jpg");
        }
        if (patientSex === "FEMALE") {
          return require("../../media/img/female.jpg");
        }
      }
      return patientImage;
    }
  };
  return (
    <div className="tab-content">
      <div id="tab-1" className="tab-pane fade show p-0 active">
        <div className="row g-4">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="col-lg-12">
                <div className="row mt-3">
                  {jiraniData?.length > 0 &&
                    jiraniData.map((patient) => (
                      <div className="col-sm-6 col-md-4 col-lg-3 p-2 my-3">
                        <Link to={"/patient"} state={{ patientId: patient.id }}>
                          <div className="jitem">
                            <div className="">
                              <div className="position-relative">
                                <img
                                  src={getPatientImage(patient.id)}
                                  className="rounded-top w-100"
                                  alt=""
                                  style={{
                                    objectFit: "cover",
                                    height: "15rem",
                                  }}
                                />
                                <div
                                  className="text-white text-small bg-primary px-1 position-absolute shadow-1"
                                  style={{ top: "0.3rem", right: "0.3rem" }}
                                >
                                  <Icon path={mdiHospital} size={1} />
                                </div>
                              </div>
                              <div
                                className="rounded-bottom py-2 w-100"
                                style={{ color: "#6c757d" }}
                              >
                                <div className="w-100">
                                  <div>
                                    <h4 className="m-0 p-0">
                                      Jirani {patient.id}
                                    </h4>
                                  </div>
                                </div>
                                <div className="d-inline-flex gap-3">
                                  <div className="fw-bold">
                                    {patient.patient_gender}
                                  </div>
                                  <div>
                                    {patient.minimum_age} -{" "}
                                    {patient.maximum_age}
                                  </div>
                                </div>
                                <div className="w-100 jtext my-2">
                                  {" "}
                                  {patient.notes}{" "}
                                </div>
                                <div>
                                  <p className="m-0 p-0 text-small">
                                    {DateConverter(patient.date_added)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PatientsTab;
