import {
  mdiAccountPlusOutline,
  mdiAccountSupervisor,
  mdiArrowRightDropCircle,
  mdiFacebook,
  mdiHandCoin,
  mdiInstagram,
  mdiSendCircle,
  mdiSendVariant,
  mdiShare,
  mdiTwitter,
} from "@mdi/js";
import Icon from "@mdi/react";
import { AddLocation } from "@mui/icons-material";
import { Button, Fab } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function Welcome() {
  const navigate = useNavigate();
  const goToLoginPage = () => {
    navigate("/login");
  };
  return (
    <>
      <div className="container-fluid p-0 mb-5">
        <div
          id="header-carousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                className="w-100"
                src={require("../../media/img/WelcomeD.jpg")}
                alt="Image"
                style={{ objectFit: "cover", height: "90vh" }}
              />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 pt-5">
                      <h1 className="display-4 text-white mb-3">Jirani</h1>
                      <p className="fs-5 text-white-50 mb-5">
                        you'll Found Them Safe
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                className="w-100"
                src={require("../../media/img/WelcomeD.jpg")}
                alt="Image"
                style={{ objectFit: "cover", height: "90vh" }}
              />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-7 pt-5">
                      <h1 className="display-4 text-white mb-3">
                        Let's Save More Lifes With Our Helping Hand
                      </h1>
                      <p className="fs-5 text-white-50 mb-5">
                        you'll Found Them Safe
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-sm-none">
            <button
              className="carousel-control-prev bg-transparent"
              type="button"
              data-bs-target="#header-carousel"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next bg-transparent"
              type="button"
              data-bs-target="#header-carousel"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>

      <div
        className="w-100 py-3 bg-light text-center position-sticky top-0"
        style={{ zIndex: "999" }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={goToLoginPage}
        >
          Get started
        </Button>
      </div>

      <section className="py-5 bg-light">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="">
                <img
                  src={require("../../media/img/WelcomeD.jpg")}
                  alt=""
                  className="w-100"
                  style={{ height: "25rem", objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="">
                <div className="py-2">
                  <h2>
                    About <span>Us</span>
                  </h2>
                </div>
                <div className="py-2">
                  <p style={{ fontSize: "1.1rem" }}>
                    Hospitals and law enforcement face the ongoing challenge of
                    attending to unidentified patients and lost individuals.
                    This situation not only burdens hospital resources as they
                    care for these anonymous patients until their kin are
                    located but also overwhelms police with numerous unresolved
                    missing person reports. The search for lost loved ones is
                    often difficult, with a lengthy chain of processes
                    complicating the journey to reunification. Additionally,
                    these unidentified patients themselves are in urgent need of
                    assistance.
                  </p>
                </div>
                <div className="pt-3">
                  <a className="btn py-3 px-5 bg-primary text-white">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4">
        <div className="py-4 text-center">
          <div className="container ">
            <div className="pb-2">
              <h2>Our Features</h2>
              <p>Jirani Webpage is dedicated to offer the following features</p>
            </div>
            <div className="row d-flex align-content-stretch">
              <div className="col-6 col-md-3 py-1">
                <div className="bg-light p-3 rounded h-100">
                  <div className="py-2">
                    <i className="text-primary">
                      <Icon path={mdiAccountPlusOutline} size={3} />
                    </i>
                  </div>
                  <div className="">
                    <h5>Register</h5>
                    <p>
                      Institution admin can register the unknown patient or the
                      lost individuals
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-3 py-1">
                <div className="bg-light p-3 rounded h-100">
                  <div className="py-2">
                    <i className="text-primary">
                      <Icon path={mdiShare} size={3} />
                    </i>
                  </div>
                  <div className="">
                    <h5>Sharing</h5>
                    <p>
                      sharing the selected individuals to the social networks
                      (Twitter-X, Facebook, Instagram)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-3 py-1">
                <div className="bg-light p-3 rounded h-100">
                  <div className="py-2">
                    <i className="text-primary">
                      <Icon path={mdiAccountSupervisor} size={3} />
                    </i>
                  </div>
                  <div className="">
                    <h5>Public</h5>
                    <p>
                      Public will have access to only the selected Jiranis as to
                      assist gathering information
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-3 py-1">
                <div className="bg-light p-3 rounded h-100">
                  <div className="py-2">
                    <i className="text-primary">
                      <Icon path={mdiHandCoin} size={3} />
                    </i>
                  </div>
                  <div className="">
                    <h5>Donation</h5>
                    <p>
                      Public and organisation will have room to donate for the
                      special jiranis
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5">
              <a href="" className="btn bg-primary px-5 py-3 text-white">
                See more features
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid donate my-5 py-5 bg-dark">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
                Suscribe Now
              </div>
              <h1 className="display-6 text-white mb-5">
                Get updated of every new jirani added
              </h1>
              <p className="text-white-50 mb-0">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit.
                Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit,
                sed stet lorem sit clita duo justo magna dolore erat amet
              </p>
            </div>
            <div className="col-lg-6">
              <div className="h-100 bg-white p-5">
                <form>
                  <div className="row g-3">
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control bg-light border-0"
                          id="email"
                          placeholder="Your Email"
                        />
                        <label for="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary px-5"
                        style={{ height: "60px" }}
                      >
                        Subscribe
                        <span className="ms-2">
                          <Icon path={mdiSendCircle} size={1} />
                        </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="mb-5" style={{ maxWidth: "500px" }}>
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
              Team Members
            </div>
            <h1 className="display-6 mb-5">
              Let's Meet With Our Ordinary Soldiers
            </h1>
          </div>
          <div className="row g-4">
            <div className="col-6 col-lg-3 my-2" data-wow-delay="0.1s">
              <div className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img
                    className="w-100"
                    src={require("../../media/img/aloson2.jpg")}
                    alt=""
                    style={{ height: "15rem", objectFit: "cover" }}
                  />
                </div>
                <div className="team-text bg-light text-center p-2">
                  <h5 className="text-truncate">Grayson Aloyce</h5>
                  <p className="text-primary">Founder</p>
                  <div className="team-social text-center">
                    <a className="btn btn-square" href="">
                      <Icon path={mdiFacebook} size={2} />{" "}
                    </a>
                    <a className="btn btn-square" href="">
                      <Icon path={mdiTwitter} size={2} />
                    </a>
                    <a className="btn btn-square" href="">
                      <Icon path={mdiInstagram} size={2} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 my-2" data-wow-delay="0.1s">
              <div className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img
                    className="w-100"
                    src={require("../../media/img/kabeta.jpg")}
                    alt=""
                    style={{ height: "15rem", objectFit: "cover" }}
                  />
                </div>
                <div className="team-text bg-light text-center p-2">
                  <h5 className="text-truncate">Weston Christopher</h5>
                  <p className="text-primary">Co-founder</p>
                  <div className="team-social text-center">
                    <a className="btn btn-square" href="">
                      <Icon path={mdiFacebook} size={1} />{" "}
                    </a>
                    <a className="btn btn-square" href="">
                      <Icon path={mdiTwitter} size={1} />
                    </a>
                    <a className="btn btn-square" href="">
                      <Icon path={mdiInstagram} size={1} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 my-2" data-wow-delay="0.5s">
              <div className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img
                    className="w-100"
                    src={require("../../media/img/mwaisa2.png")}
                    alt=""
                    style={{ height: "15rem", objectFit: "cover" }}
                  />
                </div>
                <div className="team-text bg-light text-center p-2">
                  <h5 className="text-truncate">Kelvin Mwaisaka</h5>
                  <p className="text-primary">Co-founder</p>
                  <div className="team-social text-center">
                    <a className="btn btn-square" href="">
                      <Icon path={mdiFacebook} size={1} />{" "}
                    </a>
                    <a className="btn btn-square" href="">
                      <Icon path={mdiTwitter} size={1} />
                    </a>
                    <a className="btn btn-square" href="">
                      <Icon path={mdiInstagram} size={1} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 my-2" data-wow-delay="0.5s">
              <div className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img
                    className="w-100"
                    src={require("../../media/img/jairo2.jpg")}
                    alt=""
                    style={{ height: "15rem", objectFit: "cover" }}
                  />
                </div>
                <div className="team-text bg-light text-center p-2">
                  <h5 className="text-truncate">Sarafina Jairo</h5>
                  <p className="text-primary">researcher</p>
                  <div className="team-social text-center">
                    <a className="btn btn-square" href="">
                      <Icon path={mdiFacebook} size={1} />{" "}
                    </a>
                    <a className="btn btn-square" href="">
                      <Icon path={mdiTwitter} size={1} />
                    </a>
                    <a className="btn btn-square" href="">
                      <Icon path={mdiInstagram} size={1} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="contact_section layout_padding my-4 py-4 bg-light">
        <div className="container py-5">
          <div className="pb-4">
            <h2>You have something to say?</h2>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="form_container contact-form">
                <form action="">
                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        <input type="text" placeholder="Your Name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <input type="text" placeholder="Phone Number" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <input type="email" placeholder="Email" />
                  </div>
                  <div>
                    <textarea
                      type="text"
                      className="w-100 border-0 p-2"
                      rows={4}
                      placeholder="Message"
                    />
                  </div>
                  <div className="py-3">
                    <button>Send comment</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              <div className="map_container">
                <div className="map">
                  <div id="">
                    <img
                      alt=""
                      src={require("../../media/img/WelcomeD.jpg")}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Welcome;
