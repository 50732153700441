import {
  mdiAccountCircle,
  mdiLocationExit,
  mdiMagnify,
  mdiPlus,
  mdiPlusCircle,
} from "@mdi/js";
import Icon from "@mdi/react";
import { Dropdown, DropdownToggle } from "react-bootstrap";
import React, { useState } from "react";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import API from "../../api/API";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../Loader";

function Navbar() {
  const token = useSelector((state) => state.authorization.token);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();

  //redirect to patient form
  const toPatientForm = () => {
    navigate("/patientForm");
  };

  const gotoHomePage = () => {
    navigate("/");
  };

  const logOut = async () => {
    setIsLoading(true);
    const response = await API.logOut(token);
    if (!response) {
      toast.error("Network Problem, Try again.");
      setIsLoading(false);
    }
    if (response.status === 204) {
      localStorage.removeItem("jirani");
      localStorage.removeItem("jiraniId");
      setIsLoading(false);
      navigate("welcome");
    } else {
      toast.error("Something is Wrong, try again.");
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="px-0 position-sticky" style={{ top: "0", zIndex: "99" }}>
        <nav className="navbar navbar-light bg-light navbar-expand-xl px-lg-5 px-md-3 px-sm-2 border-bottom">
          <a
            className="navbar-brand"
            onClick={gotoHomePage}
            style={{ cursor: "pointer" }}
          >
            <h1 className="text-primary display-6">Jirani</h1>
          </a>
          <button
            className="navbar-toggler py-2 px-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars text-primary"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav mx-auto align-items-center">
              <Link to={"/"}>
                <a className="nav-item nav-link active">Home</a>
              </Link>
              <Link to={"/dashboard"}>
                <a className="nav-item nav-link active">Dashboard</a>
              </Link>
              <a
                className="nav-item nav-link btn btn-primary btn-md-square rounded-circle text-white"
                onClick={() => toPatientForm()}
              >
                <Icon path={mdiPlus} size={1} color={"white"} />
              </a>
              <a className="nav-item nav-link btn-sm">
                <button
                  className="btn border border-primary btn-md-square rounded-circle bg-white text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#searchModal"
                >
                  <Icon path={mdiMagnify} size={1} />
                </button>
              </a>
            </div>
            <div className="d-flex m-3 me-0 ">
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  className="custom-dropdown-toggle"
                >
                  <Icon
                    path={mdiAccountCircle}
                    size={2}
                    className="text-primary"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="mt-2">
                  <Dropdown.Item className="py-2" onClick={logOut}>
                    <div className="d-flex justify-content-between ">
                      <div className="text-danger py-1">Logout</div>
                      <div className="text-danger py-1">
                        <Icon path={mdiLocationExit} size={1} />
                      </div>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
