import { mdiEmail, mdiMapMarker, mdiPencil, mdiPhone } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../api/API";
import { setSavedInstitutions } from "../../redux/JiraniSlice";
import { Oval } from "react-loader-spinner";
import AddedImageTile from "./AddedImageTile";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

function AddJirani() {
  const token = useSelector((state) => state.authorization.token);
  const userData = useSelector((state) => state.authorization.userData);
  const [selectedImages, setSelectedImages] = useState([]);
  const [patientData, setPatientData] = useState({});
  const savedInstitutions = useSelector(
    (state) => state.jirani.savedInstitutions
  );
  const [institutions, setInstitutions] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [sendingData, setSendingData] = useState(false);
  const [errorFields, setErrorField] = useState({
    date: false,
    diagnosis: false,
    notes: false,
    gender: false,
    images: false,
  });
  const [ageRange, setAgeRange] = useState({
    minimumAge: 5,
    maximumAge: 35,
  });
  const dispatch = useDispatch();
  const navigation = useNavigate();

  useEffect(() => {
    const staffInstitution = userData.staff_institution;
    //Loading the institutions Details
    if (savedInstitutions.length > 0) {
      setInstitutions(savedInstitutions);
      setIsLoading(false);
    }

    const institutionFetch = async () => {
      const response = await API.getInstitution(token);
      if (response.status === 200) {
        setInstitutions(response.data);
        dispatch(setSavedInstitutions(response.data));
        setIsLoading(false);
      }
    };
    institutionFetch();
  }, []);

  let filteredInstitution;
  if (institutions?.length > 0 && userData) {
    filteredInstitution = institutions.filter(
      (inst) => inst.institution_name === userData.staff_institution
    )[0];
  }

  //handle file changes
  const handleImages = (e) => {
    const file = e.target.files[0];
    const name = file?.name;
    if (selectedImages.length < 4) {
      if (file) {
        setErrorField({ ...errorFields, ["images"]: false });
        const reader = new FileReader();
        reader.onloadend = () => {
          const arr = [...selectedImages];
          arr.push({ name: name, img: reader.result, file: file });
          setSelectedImages(arr);
        };
        reader.readAsDataURL(file);
      }
    } else {
      toast.error("You can add Only 4 Picture");
    }
  };

  //handle data input
  const handleData = (e) => {
    const name = e.target?.name;
    const value = e.target.value;
    setErrorField({ ...errorFields, [name]: false });
    setPatientData({ ...patientData, [name]: value });
  };

  //handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(patientData).length < 4 || selectedImages.length < 1) {
      toast.error("Please fill all the Required Informations");
      const errors = { ...errorFields };
      !patientData.date && (errors["date"] = true);
      !patientData.gender && (errors["gender"] = true);
      !patientData.diagnosis && (errors["diagnosis"] = true);
      !patientData.notes && (errors["notes"] = true);
      selectedImages.length < 1 && (errors["images"] = true);
      setErrorField(errors);
    } else {
      const data = {};
      data["token"] = token;
      data["gender"] = patientData.gender;
      data["minAge"] = ageRange.minimumAge;
      data["maxAge"] = ageRange.maximumAge;
      data["patient_diagnosis"] = patientData.diagnosis;
      data["notes"] = patientData.notes;
      data["admissionDate"] = patientData.date;
      data["selectedImages"] = selectedImages;

      const sendingData = async () => {
        setSendingData(true);
        const response = await API.addPatientRequest(data);

        if (!response) {
          setSendingData(false);
          toast.error("Network problem, try again or try to refresh the page");
        }

        if (response.status === 201) {
          setSendingData(false);
          //redirecting to patient page
          navigation("/", { state: { id: response.data.id } });
        } else {
          toast.error(
            "There is a problem with the information, try again or consult the customer Care"
          );
        }
      };

      sendingData();
    }
  };

  const removeImg = (name) => {
    const remaining = selectedImages.filter((img) => img?.name !== name);
    setSelectedImages(remaining);
  };

  const [nameModal, setNameModal] = useState(false);

  const switchModal = () => {
    setNameModal(!nameModal);
  };

  const AdditionModal = () => {
    return (
      <>
        <ReactModal
          isOpen={nameModal}
          style={styles.modalStyle}
          contentLabel="Client Name"
        >
          <p>modal for adding patient name</p>
        </ReactModal>
      </>
    );
  };

  //age range properties

  const onSliderChange = (value) => {
    const minimumAge = value[0];
    const maximumAge = value[1];
    setAgeRange({
      ...ageRange,
      ["minimumAge"]: minimumAge,
      ["maximumAge"]: maximumAge,
    });
  };

  return (
    <div className="container-fluid contact">
      <AdditionModal />
      <div className="container mt-3">
        <div className="p-5 bg-light rounded">
          <div className="row g-4">
            <div className="col-12">
              <div
                className="text-center mx-auto"
                style={{ maxWidth: "700px;" }}
              >
                <h1 className="text-primary">Add jirani</h1>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="p-3 rounded d-inline-flex bg-white w-100 my-1">
                    <div>
                      {" "}
                      <i className="text-primary me-4">
                        <Icon path={mdiMapMarker} size={1} />
                      </i>{" "}
                    </div>
                    <div>
                      {isLoading ? (
                        <Oval size={5} />
                      ) : (
                        filteredInstitution && (
                          <>
                            <h6>{filteredInstitution.institution_name}</h6>
                            <p className="mb-2">
                              {filteredInstitution.institution_location}
                            </p>
                          </>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="p-3 rounded d-inline-flex bg-white w-100 my-1">
                    <div>
                      {" "}
                      <i className="text-primary me-4">
                        <Icon path={mdiEmail} size={1} />
                      </i>{" "}
                    </div>
                    <div>
                      {isLoading ? (
                        <Oval size={5} />
                      ) : (
                        filteredInstitution && (
                          <>
                            <h6>Mail Us</h6>
                            <p className="mb-2">
                              {filteredInstitution.institution_email}
                            </p>
                          </>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="p-3 rounded d-inline-flex bg-white w-100 my-1">
                    <div>
                      {" "}
                      <i className="fa fa-phone-alt text-primary me-4">
                        <Icon path={mdiPhone} size={1} />
                      </i>{" "}
                    </div>
                    <div>
                      {isLoading ? (
                        <Oval size={5} />
                      ) : (
                        filteredInstitution && (
                          <>
                            <h6>Telephone</h6>
                            <p className="mb-2">
                              {filteredInstitution.institution_phonenumber}
                            </p>
                          </>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <form action="">
                <div className="row">
                  <div className="col-md-7 px-3">
                    <div className="position-relative form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="jiraniName"
                        placeholder="JiraniName"
                        disabled
                      />
                      <label for="jiraniName" id="JiraniLabel">
                        Jirani Id &#40;Autogenerated&#41;
                      </label>

                      <div
                        className="position-absolute"
                        style={{ right: "0.5rem", top: "0.5rem" }}
                      >
                        <button
                          type="button"
                          className="btn bg-white text-primary"
                          id="editButton"
                          onClick={switchModal}
                        >
                          <i id="editIcon">
                            <Icon path={mdiPencil} size={1} />
                          </i>
                        </button>
                      </div>
                    </div>
                    {errorFields.gender && (
                      <p className="text-danger">Required</p>
                    )}
                    <div>
                      <select
                        className="w-100 form-control p-3 mb-4 bg-white"
                        id="jiraniSex"
                        name="gender"
                        onChange={handleData}
                      >
                        <option disabled selected>
                          Select sex
                        </option>
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                    </div>

                    <div className="mb-4">
                      <div className="mb-2">
                        Age range: <span id="curr"></span>{" "}
                      </div>
                      <Slider
                        range
                        className="form-Range"
                        min={0}
                        max={100}
                        defaultValue={[15, 45]}
                        allowCross={false}
                        onChange={onSliderChange}
                      />
                      <div className="mb-2">
                        minimum: <span id="curr">{ageRange.minimumAge}</span>{" "}
                        <span className="float-end">
                          Maximum Age: {ageRange.maximumAge}
                        </span>
                      </div>
                    </div>

                    {errorFields.date && (
                      <p className="text-danger">Required</p>
                    )}
                    <div className="form-floating my-3">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Reporting date"
                        name="date"
                        onChange={handleData}
                      />
                      <label for="date">Reporting date or Admission Date</label>
                    </div>
                    {errorFields.diagnosis && (
                      <p className="text-danger">Required</p>
                    )}
                    <div className="form-floating my-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Reproting reason"
                        name="diagnosis"
                        onChange={handleData}
                      />
                      <label for="reason">Reporting reason / diagnosis</label>
                    </div>
                  </div>
                  {errorFields.notes && <p className="text-danger">Required</p>}
                  <div className="col-lg-5 px-3">
                    <div>
                      <div className="h-auto">
                        <textarea
                          className="w-100 form-control mb-4"
                          rows="5"
                          placeholder="Brief description"
                          name="notes"
                          onChange={handleData}
                        ></textarea>
                      </div>
                      {errorFields.images && (
                        <p className="text-danger">Required</p>
                      )}
                      <div className="my-2">
                        <input
                          className="form-control mb-4"
                          type="file"
                          name="jiraniImage"
                          placeholder="Chose 4 Images"
                          capture
                          multiple
                          onChange={handleImages}
                        />
                      </div>
                      <div>
                        <div className="row w-100 pb-4">
                          {selectedImages.length > 0 &&
                            selectedImages.map((item) => (
                              <AddedImageTile
                                imagePath={item.img}
                                action={() => removeImg(item?.name)}
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row py-3 justify-content-center">
                  <div className="col-7">
                    <button
                      className="w-100 btn form-control border-primary py-3 bg-white text-primary "
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  modalStyle: {
    top: "30%",
    left: "30%",
    right: "30%",
    bottom: "30",
    marginRight: "30%",
    marginRight: "30",
    transform: "translate(-50%, -50%)",
  },
};

export default AddJirani;
