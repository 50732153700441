import {
  mdiBlockHelper,
  mdiCalendarToday,
  mdiFacebook,
  mdiGenderMale,
  mdiHelpCircle,
  mdiInstagram,
  mdiLocationEnter,
  mdiLocationExit,
  mdiMapMarker,
  mdiPencil,
  mdiPlusCircle,
  mdiStarBox,
  mdiTwitter,
  mdiWhatsapp,
} from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../auth/axiosInstance";
import { setSavedJirani } from "../../redux/JiraniSlice";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../Loader";
import DateConverter from "../../utils/DateConverter";
import ToastNetworkError from "../../utils/ToastNetworkError";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import API from "../../api/API";
import RecentAddedJirani from "./RecentAddedJirani";

function JiraniView(props) {
  const token = useSelector((state) => state.authorization.token);
  const savedJirani = useSelector((state) => state.jirani.savedJirani);
  const [isLoading, setIsLoading] = useState(true);
  const [patientData, setPatientData] = useState();
  const [activeImage, setActiveImage] = useState("image");
  const dispatch = useDispatch();
  const location = useLocation();
  const jiraniId = location.state.patientId;

  useEffect(() => {
    if (savedJirani.length > 0) {
      const jirani = savedJirani.filter((patient) => patient.id === jiraniId);
      setPatientData(jirani[0]);
      setIsLoading(false);
    }

    const jiraniFetch = async () => {
      const response = await API.patientsFetch(token);
      if (!response) {
        toast.error("Network Problem, try to refresh", { autoClose: true });
      }
      if (response.status === 200) {
        const patients = response.data;
        const jirani = patients.filter((patient) => patient.id === jiraniId);
        setPatientData(jirani[0]);
        dispatch(setSavedJirani(patients));
        setIsLoading(false);
      } else {
        toast.error("Network Problem, try to refresh", { autoClose: true });
      }
    };
    jiraniFetch();
  }, []);

  //return picture based on gender
  const getPatientPicture = (gender) => {
    if (gender === "MALE") {
      return require("../../media/img/male.jpg");
    } else {
      return require("../../media/img/female.jpg");
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="container-fluid mt-3">
        <div className="container">
          <div className="row g-4 mb-5">
            <div className="col-lg-8 col-xl-9">
              <div className="row g-4">
                <div className="col-lg-6">
                  <div
                    className="carousel slide"
                    id="carouselView"
                    data-bs-ride="carousel"
                    data-bs-interval="3000"
                  >
                    <div className="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselView"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselView"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselView"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselView"
                        data-bs-slide-to="3"
                        aria-label="Slide 4"
                      ></button>
                    </div>

                    <div className="carousel-inner">
                      <div
                        className={`carousel-item ${
                          activeImage === "image" && "active"
                        }`}
                      >
                        <div className="img-border-radius bg-light rounded">
                          <img
                            src={
                              patientData?.patient_images
                                ? patientData?.patient_images
                                : getPatientPicture(patientData.patient_gender)
                            }
                            alt=""
                            className="w-100 rounded"
                            style={{ height: "32rem", objectFit: "cover" }}
                          />
                        </div>
                      </div>
                      <div
                        className={`carousel-item ${
                          activeImage === "image2" && "active"
                        }`}
                      >
                        <div className="img-border-radius bg-light rounded">
                          <img
                            src={
                              patientData?.patient_images2
                                ? patientData?.patient_images2
                                : getPatientPicture(patientData.patient_gender)
                            }
                            alt=""
                            className="w-100 rounded"
                            style={{ height: "32rem", objectFit: "cover" }}
                          />
                        </div>
                      </div>
                      <div
                        className={`carousel-item ${
                          activeImage === "image3" && "active"
                        }`}
                      >
                        <div className="img-border-radius bg-light rounded">
                          <img
                            src={
                              patientData?.patient_images3
                                ? patientData?.patient_images3
                                : getPatientPicture(patientData.patient_gender)
                            }
                            alt=""
                            className="w-100 rounded"
                            style={{ height: "32rem", objectFit: "cover" }}
                          />
                        </div>
                      </div>
                      <div
                        className={`carousel-item ${
                          activeImage === "image4" && "active"
                        }`}
                      >
                        <div className="img-border-radius bg-light rounded">
                          <img
                            src={
                              patientData?.patient_images4
                                ? patientData?.patient_images4
                                : getPatientPicture(patientData.patient_gender)
                            }
                            alt=""
                            className="w-100 rounded"
                            style={{ height: "32rem", objectFit: "cover" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h2 className="fw-bold mb-1 pb-1">
                        {patientData?.patient_first_name
                          ? `${patientData?.patient_first_name} ${patientData?.patient_last_name}`
                          : `Jirani ${patientData.id}`}
                      </h2>
                    </div>
                    <div>
                      <button className="btn bg-info text-dark py-2">
                        <Icon path={mdiBlockHelper} size={0.8} /> Pending
                      </button>
                    </div>
                  </div>
                  <div className="d-inline-flex gap-3">
                    <div className="d-inline-flex gap-1">
                      <div>
                        <Icon path={mdiGenderMale} size={0.8} />
                      </div>
                      <div>{patientData.patient_gender}</div>
                    </div>
                    <div className="d-inline-flex gap-1">
                      <div>
                        <Icon path={mdiCalendarToday} size={0.8} />
                      </div>
                      <div>
                        {patientData?.patient_age
                          ? patientData?.patient_age
                          : ` ${patientData?.minimum_age}-${patientData?.maximum_age}`}{" "}
                        Years
                      </div>
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="d-inline-flex gap-3">
                      <div>
                        <Icon path={mdiMapMarker} size={0.8} />
                      </div>
                      <div>
                        <h6 className="m-0 p-0">
                          {patientData?.patient_institution?.institution_name}
                        </h6>
                        <div className="text-small">Hospital</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 py-2">
                      <div className="bg-light p-3 rounded">
                        <div className="d-inline-flex gap-2">
                          <div>
                            <Icon path={mdiLocationEnter} size={0.8} />
                          </div>
                          <div>Date admitted</div>
                        </div>
                        <div className="mt-3 px-2">
                          <h6>{DateConverter(patientData?.admission_date)}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 py-2">
                      <div className="bg-light p-3 rounded">
                        <div className="d-inline-flex gap-2">
                          <div>
                            <Icon path={mdiPlusCircle} size={0.8} />
                          </div>
                          <div>Date addded</div>
                        </div>
                        <div className="mt-3 px-2">
                          <h6>{DateConverter(patientData?.date_added)}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 py-2">
                      <div className="bg-light p-3 rounded">
                        <div className="d-inline-flex gap-2">
                          <div>
                            <Icon path={mdiHelpCircle} size={0.8} />
                          </div>
                          <div>Reason for admission</div>
                        </div>
                        <div className="mt-3 px-2">
                          <h6>
                            {patientData?.patient_diagnosis
                              ? patientData?.patient_diagnosis
                              : "Not well Defined"}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 py-2">
                      <div className="bg-light p-3 rounded">
                        <div className="d-inline-flex gap-2">
                          <div>
                            <Icon path={mdiLocationExit} size={0.8} />
                          </div>
                          <div>Date discharged</div>
                        </div>
                        <div className="mt-3 px-2">
                          <h6>
                            {patientData?.disharge_date
                              ? DateConverter(patientData?.disharge_date)
                              : "not yet discharged"}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="d-inline-flex gap-2 py-2">
                      <div>
                        <a
                          href="#"
                          className="btn border border-primary rounded-pill py-2 text-primary"
                        >
                          <i className="me-2 text-primary">
                            <Icon path={mdiPencil} size={0.8} />
                          </i>
                          Edit
                        </a>
                      </div>
                      <div>
                        <a
                          href="#"
                          className="btn border border-primary rounded-pill py-2 text-primary"
                        >
                          <i className="me-2 text-primary">
                            <Icon path={mdiStarBox} size={0.8} />
                          </i>
                          add to special
                        </a>
                      </div>
                    </div>
                    <div className="px-3">
                      <div className="d-inline-flex gap-2 flex-wrap">
                        <div>
                          <a href="#" className="btn rounded-pill">
                            <Icon path={mdiWhatsapp} size={1} />
                          </a>
                        </div>
                        <div>
                          <a href="#" className="btn rounded-pill">
                            <Icon path={mdiFacebook} size={1} />
                          </a>
                        </div>
                        <div>
                          <a href="#" className="btn rounded-pill">
                            <Icon path={mdiTwitter} size={1} />
                          </a>
                        </div>
                        <div>
                          <a href="#" className="btn rounded-pill">
                            <Icon path={mdiInstagram} size={1} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <nav>
                    <div className="nav nav-tabs mb-3">
                      <button
                        className="nav-link active border-white border-bottom-0"
                        type="button"
                        role="tab"
                        id="nav-about-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-about"
                        aria-controls="nav-about"
                        aria-selected="true"
                      >
                        Description
                      </button>
                      <button
                        className="nav-link border-white border-bottom-0"
                        type="button"
                        role="tab"
                        id="nav-mission-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        aria-controls="nav-mission"
                        aria-selected="false"
                      >
                        Reviews
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content mb-5">
                    <div
                      className="tab-pane active"
                      id="nav-about"
                      role="tabpanel"
                      aria-labelledby="nav-about-tab"
                    >
                      <p>{patientData?.notes}</p>
                    </div>
                    <div
                      className="tab-pane"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission-tab"
                    >
                      <div className="d-flex py-2">
                        <img
                          src={require("../../media/img/male.jpg")}
                          className="img-fluid rounded-circle p-3"
                          style={{ width: "100px", height: "100px" }}
                          alt=""
                        />
                        <div>
                          <div className="d-flex justify-content-between">
                            <h5 className="p-0 m-0">Grayson Aloyce</h5>
                            <div className="d-flex mb-3">
                              <p className="p-0 m-0 text-small">
                                April 12, 2024
                              </p>
                            </div>
                          </div>
                          <p>
                            Jirani org we promise the family of the above
                            patient that we do our best tomake sure that when
                            they found, they'll be safe.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <form action="#">
                  <h4 className="fw-bold">Leave a comment</h4>
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="border-bottom rounded mt-4">
                        <textarea
                          name=""
                          id=""
                          className="form-control border-0"
                          cols="30"
                          rows="2"
                          placeholder="Your Review *"
                          spellcheck="false"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="d-flex justify-content-between mb-5">
                        <a
                          href="#"
                          className="btn border border-primary text-primary rounded-pill px-4 py-3"
                        >
                          Send your comment
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <RecentAddedJirani />
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
}

export default JiraniView;
