import { useSelector } from "react-redux";
import axiosInstance from "../auth/axiosInstance";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";

const apiSettings = {
  //authentifications
  logOut: async (token) => {
    const response = await axiosInstance
      .post(
        `/auth/logout/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });
    return response;
  },

  //Institution model form management and Api
  institutionModelForm: async (data) => {
    let formData = new FormData();
    let token = data.token;
    if (data.patientData.PatientImage) {
      formData.append(
        "institution_logo",
        data.patientData.PatientImage,
        data.patientData.PatientImage.name
      );
    }
    formData.append("institution_name", "morogoro rrh");
    formData.append("institution_location", "morogoro");
    formData.append("institution_phonenumber", "+255768202520");
    formData.append("institution_email", "morogororrh@gmail.com");
    formData.append("institution_type", "hospital");

    const response = await axiosInstance
      .post("/institutions/institution/", formData, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });

    return response;
  },

  getInstitution: async (token) => {
    const response = await axiosInstance
      .get(`/institutions/institution/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });
    return response;
  },

  //patient form management and APIs
  addPatientRequest: async (data) => {
    let formData = new FormData();
    const token = data.token;
    const date = new Date(data.admissionDate);

    formData.append(
      "patient_images",
      data.selectedImages[0].file,
      data.selectedImages[0].file.name
    );
    if (data.selectedImages.length > 1) {
      for (let index = 0; index < data.selectedImages.length; index++) {
        if (index === 0) {
          formData.append(
            `patient_images`,
            data.selectedImages[index].file,
            data.selectedImages[index].file.name
          );
        } else {
          formData.append(
            `patient_images${index}`,
            data.selectedImages[index].file,
            data.selectedImages[index].file.name
          );
        }
      }
    }
    formData.append("patient_gender", data.gender.toUpperCase());
    formData.append("minimum_age", data.minAge);
    formData.append("maximum_age", data.maxAge);
    formData.append("patient_diagnosis", data.diagnosis);
    formData.append("notes", data.notes);
    formData.append("admission_date", data.admissionDate);

    const response = await axiosInstance
      .post(`/jirani/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
    return response;
  },

  patientsFetch: async (token) => {
    const response = await axiosInstance
      .get("/jirani/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });
    return response;
  },

  specialPatientFetch: async (token) => {
    const response = await axiosInstance
      .get(`/jirani/special/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });
    return response;
  },
};

export default apiSettings;
