import axiosInstance from "../auth/axiosInstance";

export const Statistics = {
  getInstitutionTypeClients: (data) => {
    const patients = data.patients;
    const institution = data.institution;
    const hospitalData = patients.filter(
      (patient) => patient.patient_institution?.institution_type === institution
    );
    return hospitalData;
  },
};

export default Statistics;
