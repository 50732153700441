import { createSlice } from "@reduxjs/toolkit";
import env from "react-dotenv";
import CryptoJS from "crypto-js";

//load the saved token
let savedToken;
const secretKey = env.ENCRYPTION_KEY;

try {
  const savedTokenHash = localStorage.getItem("jirani");
  savedToken = CryptoJS.AES.decrypt(savedTokenHash, secretKey);
  savedToken = savedToken.toString(CryptoJS.enc.Utf8);
} catch (error) {}

const initialState = {
  token: savedToken ? savedToken : "",
  userData: [],
  savedSelectedInstitute: "all",
};

export const Authslice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setSavedSelectedInstitute: (state, action) => {
      state.savedSelectedInstitute = action.payload;
    },
  },
});

export const { setToken, setUserData, setSavedSelectedInstitute } =
  Authslice.actions;

export default Authslice.reducer;
