import React, { useEffect, useState } from "react";
import axiosInstance from "../../auth/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setSavedJirani } from "../../redux/JiraniSlice";
import Loader from "../Loader";
import DateConverter from "../../utils/DateConverter";
import Icon from "@mdi/react";
import { mdiHospital } from "@mdi/js";
import { Link, useNavigate } from "react-router-dom";
import PatientsTab from "./PatientsTab";
import { setSavedSelectedInstitute } from "../../redux/AuthSlice";

function AvailablePatients() {
  const token = useSelector((state) => state.authorization.token);
  const savedJirani = useSelector((state) => state.jirani.savedJirani);
  const [isLoading, setIsLoading] = useState(true);
  const [jiraniData, setJiraniData] = useState();
  const [selectedInstitute, setSelectedInstitute] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedInstitute("all");
    //loading the cashed Data if available before fetching
    if (savedJirani?.length > 0) {
      setJiraniData(savedJirani);
      setIsLoading(false);
    }

    const jiraniFetch = async () => {
      await axiosInstance
        .get("/jirani/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          setJiraniData(response.data);
          dispatch(setSavedJirani(response.data));
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error("Network problems", { autoClose: true });
        });
    };
    jiraniFetch();
  }, []);

  const switchInstitute = (e) => {
    const name = e.target.innerText;
    let institute;
    name === "Hospital" && (institute = name.toLowerCase());
    name === "Police" && (institute = name.toLowerCase());
    name === "All Jirani" && (institute = "all");
    setSelectedInstitute(institute);
    dispatch(setSavedSelectedInstitute(institute));
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="container-fluid fruite">
        <div className="container">
          <div className="tab-className">
            <div className="row g-4">
              <div className="col-lg-4 text-start">
                <h1>Available jirani</h1>
              </div>
              <div className="col-lg-8 text-end">
                <ul className="nav nav-pills d-inline-flex text-center mb-5">
                  <li className="nav-item" style={{ cursor: "pointer" }}>
                    <a
                      className={`d-flex m-2 py-2  rounded-pill active ${
                        selectedInstitute === "all" ? "bg-primary" : "bg-light"
                      }`}
                      data-bs-toggle="pill"
                      onClick={switchInstitute}
                    >
                      <span className="text-dark" style={{ width: "130px" }}>
                        All Jirani
                      </span>
                    </a>
                  </li>
                  <li className="nav-item" style={{ cursor: "pointer" }}>
                    <a
                      className={`d-flex m-2 py-2  rounded-pill active ${
                        selectedInstitute === "hospital"
                          ? "bg-primary"
                          : "bg-light"
                      }`}
                      data-bs-toggle="pill"
                      name="hospital"
                      onClick={switchInstitute}
                    >
                      <span className="text-dark" style={{ width: "130px" }}>
                        Hospital
                      </span>
                    </a>
                  </li>
                  <li className="nav-item" style={{ cursor: "pointer" }}>
                    <a
                      className={`d-flex m-2 py-2  rounded-pill active ${
                        selectedInstitute === "police"
                          ? "bg-primary"
                          : "bg-light"
                      }`}
                      data-bs-toggle="pill"
                      onClick={switchInstitute}
                    >
                      <span className="text-dark" style={{ width: "130px" }}>
                        Police
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <PatientsTab />
          </div>
        </div>
      </div>
    </>
  );
}

export default AvailablePatients;
