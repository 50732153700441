import React, { useEffect } from "react";
import RecentAdded from "./RecentAdded";
import AvailablePatients from "./AvailablePatients";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

function Patients() {
  const { state } = useLocation();

  useEffect(() => {
    state && toast.success("You have sucessfull registered new Jirani");
  }, []);

  return (
    <>
      {/* recent added patient*/}
      <RecentAdded />

      {/* available jirani */}
      <AvailablePatients />
    </>
  );
}

export default Patients;
