import React from "react";

const Loader = () => {
  return (
    <>
      <div
        id="spinner"
        className="show w-100 vh-100 bg-white position-fixed translate-middle top-50 start-50  d-flex align-items-center justify-content-center"
      >
        <div className="text-primary spinner-border" role="status"></div>
      </div>
      <div className="position-static" style={{ zIndex: 999 }}>
        <div
          className="position-fixed bg-primary rounded-circle"
          style={{
            width: "20rem",
            height: "20rem",
            right: "-5rem",
            top: "-2rem",
            opacity: "0.6",
          }}
        ></div>
        <div
          className="position-fixed bg-primary rounded-circle"
          style={{
            width: "100rem",
            height: "180rem",
            left: "-50rem",
            bottom: "-50rem",
            transform: "rotate(-40deg)",
          }}
        ></div>
      </div>
    </>
  );
};

export default Loader;
