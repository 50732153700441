import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../auth/axiosInstance";
import { toast } from "react-toastify";
import { setSavedJirani } from "../../redux/JiraniSlice";
import Loader from "../Loader";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import API from "../../api/API";
import { mdiReplyAllOutline } from "@mdi/js";

function RecentAdded() {
  const token = useSelector((state) => state.authorization.token);
  const savedJirani = useSelector((state) => state.jirani.savedJirani);
  const [isLoading, setIsLoading] = useState(true);
  const [jiraniData, setJiraniData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (savedJirani.length > 0) {
      setJiraniData(savedJirani);
      setIsLoading(false);
    }

    const jiraniFetch = async () => {
      const response = await API.patientsFetch(token);
      if (!response) {
        toast.error("Network problem, Try again");
      }

      if (response.status === 200) {
        setJiraniData(response.data);
        setIsLoading(false);
      }
    };
    jiraniFetch();
  }, []);

  //return picture based on gender
  const getPatientPicture = (gender) => {
    if (gender === "MALE") {
      return require("../../media/img/male.jpg");
    } else {
      return require("../../media/img/female.jpg");
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="container-fluid testimonial pt-3">
        <div className="container py-5">
          <div
            className="carousel slide"
            id="carouselSlide"
            data-bs-ride="carousel"
            data-bs-interval="2000"
          >
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselSlide"
                    data-bs-slide-to="0"
                    className="active text-primary"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselSlide"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselSlide"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>

                <div className="carousel-inner">
                  {jiraniData.slice(0, 3).map((patient, index) => (
                    <>
                      <div
                        className={`carousel-item ${index === 0 && "active"}`}
                      >
                        <div className="img-border-radius bg-light rounded p-4">
                          <div className="position-relative">
                            <i
                              className="fa fa-quote-right fa-2x text-secondary position-absolute"
                              style={{ bottom: "30px", right: "0" }}
                            ></i>
                            <div className="mb-4 pb-4 border-bottom border-secondary">
                              <p className="mb-0">{patient.notes}</p>
                            </div>
                            <div className="d-flex align-items-center flex-nowrap">
                              <div className="bg-secondary rounded">
                                <img
                                  src={
                                    patient?.patient_images
                                      ? patient?.patient_images
                                      : getPatientPicture(
                                          patient.patient_gender
                                        )
                                  }
                                  className="img-fluid rounded"
                                  style={{ width: "100px", height: "100px" }}
                                  alt=""
                                />
                              </div>
                              <div className="ms-4 d-block">
                                <h4 className="text-dark">
                                  Jirani {patient.id}
                                </h4>
                                <div className="p-0 m-0">
                                  {" "}
                                  {
                                    patient?.patient_institution
                                      ?.institution_name
                                  }
                                </div>
                                <div className="d-inline-flex gap-2">
                                  <div>
                                    <p className="fw-bold">
                                      {patient.patient_gender}
                                    </p>
                                  </div>
                                  <div>
                                    <p>
                                      {patient.minimum_age} -{" "}
                                      {patient.maximum_age}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex pe-5"> </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default RecentAdded;
