import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../Loader";
import { Oval } from "react-loader-spinner";

function OldestJirani() {
  const savedJirani = useSelector((state) => state.jirani.savedJirani);
  const [patients, setPatients] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (savedJirani.length > 0) {
      setPatients(savedJirani);
      setIsLoading(false);
    }
  }, []);

  const getDays = (registrationDate) => {
    const today = new Date();
    const registeredDate = new Date(registrationDate);
    const difference = today - registeredDate;
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    const differenceInDays = Math.floor(difference / millisecondsInADay);
    return differenceInDays;
  };

  //return picture based on gender
  const getPatientPicture = (gender) => {
    if (gender === "MALE") {
      return require("../../media/img/male.jpg");
    } else {
      return require("../../media/img/female.jpg");
    }
  };

  return (
    <div className="col-lg-4 py-2">
      <div className="card card-rounded h-100">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h4 className="card-title card-title-dash">Oldest Jiranis</h4>
                </div>
              </div>
              <div className="mt-3">
                {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <Oval size={2} wrapperClass={"d-flex"} />
                  </div>
                ) : (
                  patients
                    ?.slice(patients.length - 4, patients.length)
                    .map((patient, index) => (
                      <div
                        className="wrapper d-flex align-items-center justify-content-between py-3 border-bottom my-3"
                        key={index}
                      >
                        <div className="d-flex">
                          <img
                            className="rounded-10"
                            src={
                              patient?.patient_images
                                ? patient?.patient_images
                                : getPatientPicture(patient.patient_gender)
                            }
                            alt="profile"
                            style={{
                              height: "3rem",
                              objectFit: "cover",
                            }}
                          />
                          <div className="ms-2">
                            <h6>Jirani {patient?.id}</h6>
                            <div className="d-flex">
                              <div className="me-2">
                                {patient?.patient_gender}
                              </div>
                              <div>
                                {patient?.minimum_age} - {patient?.maximum_age}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-muted text-small">
                          {getDays(patient?.date_added)} Days
                        </div>
                      </div>
                    ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OldestJirani;
