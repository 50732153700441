import {
  mdiAccountBoxMultiple,
  mdiCheckCircle,
  mdiGenderMale,
  mdiGenderFemale,
  mdiLocationEnter,
  mdiLocationExit,
  mdiPrinter,
  mdiShare,
  mdiTrophyAward,
  mdiStarBox,
} from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Statistics from "../../utils/Statistics";
import API from "../../api/API";
import SpecialVerificationTab from "./SpecialVerificationTab";
import OldestJirani from "./OldestJirani";

function Dashboard() {
  const token = useSelector((state) => state.authorization.token);
  const [hospitalsData, setHospitalsData] = useState();
  const [policeData, setPoliceData] = useState();
  const [allData, setAllData] = useState();
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    const allDataFetch = async () => {
      setIsLoading(true);
      const response = await API.patientsFetch(token);
      if (response.status === 200) {
        const hospital = Statistics.getInstitutionTypeClients({
          patients: response.data,
          institution: "hospital",
        });
        setHospitalsData(hospital);
        const police = Statistics.getInstitutionTypeClients({
          patients: response.data,
          institution: "police",
        });
        setPoliceData(police);
      }
      setIsLoading(false);
    };
    allDataFetch();
  }, []);

  return (
    <div className="container-fluid bg-light">
      <div className="container p-1 my-3">
        <div className="row">
          <div className="col-sm-12">
            <div className="home-tab">
              <div className="d-sm-flex align-items-center justify-content-between my-3 pb-3 border-bottom">
                <div className="d-flex">
                  <div className="me-3">
                    <label for="from" className="text-dark">
                      From
                    </label>
                    <input
                      type="date"
                      name="from"
                      id="from"
                      className="p-2 border-0 text-primary rounded"
                    />
                  </div>
                  <div className="me-3">
                    <label for="to" className="text-dark">
                      To
                    </label>
                    <input
                      type="date"
                      name="to"
                      id="to"
                      className="p-2 border-0 text-primary rounded"
                    />
                  </div>
                </div>
                <div>
                  <div className="btn-wrapper">
                    <a href="#" className="btn border-primary me-3">
                      <i className="me-2">
                        {" "}
                        <Icon path={mdiShare} size={1} />{" "}
                      </i>
                      Share
                    </a>
                    <a href="#" className="btn bg-primary text-white">
                      <i className="me-2">
                        {" "}
                        <Icon path={mdiPrinter} size={1} />{" "}
                      </i>
                      Print
                    </a>
                  </div>
                </div>
              </div>
              <div className="tab-content tab-content-basic">
                <div
                  className="tab-pane fade show active"
                  id="overview"
                  role="tabpanel"
                  aria-labelledby="overview"
                >
                  <div className="row mb-2">
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="d-flex justify-content-between align-content-center bg-white p-4 rounded my-2 border">
                            <div>
                              <p className="">Total admitted</p>
                              <h3 className="">25</h3>
                            </div>
                            <div className="me-3">
                              <div className="d-flex justify-content-center align-items-center">
                                <i className="text-success">
                                  {" "}
                                  <Icon path={mdiLocationEnter} size={2} />{" "}
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="d-flex justify-content-between align-content-center bg-white p-4 rounded my-2 border">
                            <div>
                              <p className="">Total Available</p>
                              <h3 className="">15</h3>
                            </div>
                            <div className="me-3">
                              <div className="d-flex justify-content-center align-items-center">
                                <i className="text-primary">
                                  {" "}
                                  <Icon
                                    path={mdiAccountBoxMultiple}
                                    size={2}
                                  />{" "}
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="d-flex justify-content-between align-content-center bg-white p-4 rounded my-2 border">
                            <div>
                              <p className="">Special jirani</p>
                              <h3 className="">5</h3>
                            </div>
                            <div className="me-3">
                              <div className="d-flex justify-content-center align-items-center">
                                <i className="text-warning">
                                  {" "}
                                  <Icon path={mdiStarBox} size={2} />{" "}
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="d-flex justify-content-between align-content-center bg-white p-4 rounded my-2 border">
                            <div>
                              <p className="">Total discharged</p>
                              <h3 className="">10</h3>
                            </div>
                            <div className="me-3">
                              <div className="d-flex justify-content-center align-items-center">
                                <i className="text-danger">
                                  {" "}
                                  <Icon path={mdiLocationExit} size={2} />{" "}
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex align-items-stretch mb-2">
                    <div className="col-lg-8 py-2">
                      <div className="card p-4 h-100">
                        <h4>Bar chart</h4>
                        <div>
                          The chart shows the number of jiranis admitted at a
                          specified time per each age group
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 py-2">
                      <div className="card p-4 h-100">
                        <div className="bg-light p-3 rounded my-1 text-center">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <div className="me-2 text-info">
                                <Icon path={mdiGenderMale} size={1} />
                              </div>
                              <div>Male</div>
                            </div>
                            <div>
                              <div>45</div>
                            </div>
                            <div>
                              <h5>65%</h5>
                            </div>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar bg-info"
                              role="progressbar"
                              style={{ width: "65%" }}
                            ></div>
                          </div>
                        </div>
                        <div className="bg-light p-3 rounded my-1 text-center">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <div className="me-2 text-warning">
                                <Icon path={mdiGenderFemale} size={1} />
                              </div>
                              <div>Female</div>
                            </div>
                            <div>
                              <div>15</div>
                            </div>
                            <div>
                              <h5>35%</h5>
                            </div>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar bg-warning"
                              role="progressbar"
                              style={{ width: "35%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex align-items-stretch mb-2 py-2">
                    <div className="col-lg-12">
                      <div className="card p-4">
                        <h4>Line chart</h4>
                        <div>
                          The chart shows the number of jiranis admitted at a
                          specified time per each time interval
                        </div>
                        <div
                          className="card my-2 border-0"
                          style={{ height: "10rem" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex align-content-stretch">
                    <SpecialVerificationTab />
                    <OldestJirani />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
