import { mdiCalendarToday, mdiGenderMale } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../api/API";
import Loader from "../Loader";

function RecentAddedJirani() {
  const token = useSelector((state) => state.authorization.token);
  const savedJirani = useSelector((state) => state.jirani.savedJirani);
  const [allPatient, setAllPatient] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (savedJirani.length > 0) {
      setAllPatient(savedJirani);
      setIsLoading(false);
    }
    const patientFetch = async () => {
      const response = await API.patientsFetch(token);
      if (response.status === 200) {
        const patients = response.data;
        setAllPatient(patients);
        setIsLoading(false);
      }
    };
    patientFetch();
  }, []);

  const gotoHomePage = () => {
    navigate("/");
  };

  //return picture based on gender
  const getPatientPicture = (gender) => {
    if (gender === "MALE") {
      return require("../../media/img/male.jpg");
    } else {
      return require("../../media/img/female.jpg");
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="col-lg-4 col-xl-3">
      <div className="row g-4 fruite ps-3">
        <div className="col-lg-12">
          <h4 className="mb-4">Recently added jiranis</h4>
          {allPatient.slice(0, 4).map((patient) => (
            <>
              <div className="d-flex align-items-center justify-content-start gap-3 py-2">
                <div
                  className="rounded"
                  style={{ width: "100px", height: "100px" }}
                >
                  <img
                    src={
                      patient?.patient_images
                        ? patient?.patient_images
                        : getPatientPicture(patient.patient_gender)
                    }
                    className="rounded w-100"
                    style={{ height: "6rem", objectFit: "cover" }}
                    alt=""
                  />
                </div>
                <div>
                  <h6 className="mb-2 fw-bold">
                    Jirani {patient?.id || "..."}
                  </h6>
                  <div className="d-inline-flex gap-3">
                    <div className="d-inline-flex gap-1">
                      <div>
                        <Icon path={mdiGenderMale} size={0.8} />
                      </div>
                      <div>{patient?.patient_gender || "..."}</div>
                    </div>
                    <div className="d-inline-flex gap-1">
                      <div>
                        <Icon path={mdiCalendarToday} size={0.8} />
                      </div>
                      <div>
                        {patient.minimum_age} - {patient.maximum_age}
                      </div>
                    </div>
                  </div>
                  <div>
                    {patient?.patient_institution?.institution_name || "..."}
                  </div>
                </div>
              </div>
            </>
          ))}
          <div className="d-flex justify-content-center my-4">
            <a
              className="btn border border-secondary px-4 py-3 rounded-pill text-primary w-100"
              onClick={gotoHomePage}
            >
              View All
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentAddedJirani;
