import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  savedJirani: [],
  savedInstitutions: [],
  savedSpecialPatients: [],
};

export const JiraniSlice = createSlice({
  name: "jirani",
  initialState,
  reducers: {
    setSavedJirani: (state, action) => {
      state.savedJirani = action.payload;
    },
    setSavedInstitutions: (state, action) => {
      state.savedInstitutions = action.payload;
    },
    setSavedSpecialPatients: (state, action) => {
      state.savedSpecialPatients = action.payload;
    },
  },
});

export const { setSavedJirani, setSavedInstitutions, setSavedSpecialPatients } =
  JiraniSlice.actions;

export default JiraniSlice.reducer;
