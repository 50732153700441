import "./assets/css/style.css";
import "./assets/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import PrivateRoute from "./auth/PrivateRoute";
import "react-toastify/dist/ReactToastify.css";
import Patients from "./components/home/Patients";
import { ToastContainer } from "react-toastify";
import AddJirani from "./components/patientForms/AddJirani";
import JiraniView from "./components/patientPage/JiraniView";
import Dashboard from "./components/Dashboard/Dashboard";
import Welcome from "./components/welcome/Welcome";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route index element={<Patients />} />
            <Route path="/patient" element={<JiraniView />} />
            <Route path="/patientForm" element={<AddJirani />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer position="top-center" />
    </>
  );
}

export default App;
