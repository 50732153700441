import React from "react";
import Navbar from "./navbar/Navbar";
import { useSelector } from "react-redux";
import "../assets/css/style.css";
import Patients from "./home/Patients";
import { Outlet } from "react-router-dom";

function Layout() {
  const token = useSelector((state) => state.authorization.token);

  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
}

export default Layout;
