import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./AuthSlice";
import JiraniReducer from "./JiraniSlice";

export const store = configureStore({
  reducer: {
    authorization: AuthReducer,
    jirani: JiraniReducer,
  },
});
