import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "./axiosInstance";
import Layout from "../components/Layout";
import { setUserData } from "../redux/AuthSlice";
import { Navigate } from "react-router-dom";

function PrivateRoute() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const token = useSelector((state) => state.authorization.token);
  const dispatch = useDispatch();

  const userId = localStorage.getItem("jiraniId");
  useEffect(() => {
    const userFetch = async () => {
      await axiosInstance
        .get(`/auth/profile/${userId}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setIsAuthenticated(true);
            setIsLoading(false);
            dispatch(setUserData(response.data));
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    };
    userFetch();
  }, []);

  return isLoading ? (
    <Loader />
  ) : isAuthenticated ? (
    <Layout />
  ) : (
    <Navigate to={"/welcome"} />
  );
}

export default PrivateRoute;
